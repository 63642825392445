import React from "react"
import Layout from "../components/layout"
import Meta from "../components/meta"
import { Link } from "gatsby"

export default () => (
  <Layout>
    <Meta title="ページが見つかりません" />
    <style
      dangerouslySetInnerHTML={{
        __html:
          '* {"Whitney SSm A", "Whitney SSm B", "Helvetica Neue", Helvetica, Arial, Sans-Serif;}',
      }}
    />
    <div className="h-screen w-screen bg-blue-600 flex justify-center content-center flex-wrap">
      <p className="font-sans text-white text-3xl">ページが見つかりません</p>
    </div>
    <div className="absolute w-screen bottom-0 mb-6 text-white text-center font-sans text-xl">
      <Link className="border-b" to="/">
        トップへ戻る
      </Link>
    </div>
  </Layout>
)
